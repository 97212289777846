<template>
  <div>
    <!-- create a css grid with no gap and 3 columns and borders, then loop over the grouped products, within this div add a select input of all the items and when a new item is seleted mark its isSelected as true else set it as false, if the item with the isSelected true in a div showing its data, make the first item in the list selected by default -->
    <div class="pb-4 text-center text-lg font-medium">
      Choose from our textures...
    </div>
    <div class="flex flex-wrap space-y-4 sm:space-y-0">
      <div
        v-for="(group, index) in groupedProducts"
        :key="index"
        class="w-full flex-none bg-white p-0 sm:w-1/3 sm:p-2"
      >
        <div class="w-full rounded-lg border-2 py-0">
          <div
            @click="group.isOpen = !group.isOpen"
            class="flex items-center space-x-2 text-center"
          >
            <!-- <img
              class="object-cover w-24 h-24 rounded-full"
              :src="`/images/product/${group.selected.sku}.jpg`"
              :alt="group.selected.name"
              :key="group.selected.sku"
            /> -->
            <div
              class="default-transition flex origin-center items-center justify-center"
              :class="group.isOpen ? 'my-2 ml-2 h-12 w-12' : 'h-32 w-32'"
            >
              <image-getter
                classes="
                object-cover rounded-full w-full h-full
              "
                :url="`/images/product/updated/${group.selected.sku}.webp`"
                default="/images/product/awaitingimage.webp"
                :key="group.selected.sku"
              />
            </div>
            <div class="flex-grow text-left">
              <div class="text-lg font-semibold">
                {{ mappedTypes[group.label] }}
              </div>
              <div class="text-sm text-gray-700">
                {{ group.items.length }} Flavours
              </div>
              <div class="text-xs text-gray-700"></div>
            </div>
            <div class="px-4 text-sm">View</div>
          </div>
          <!-- show an image thats full width using the group seleteds image-->

          <div v-if="group.isOpen">
            <div>
              <div
                class="relative flex w-full items-center justify-center overflow-hidden"
              >
                <transition
                  enter-active-class="miniFadeInTop"
                  leave-active-class="miniFadeOutBottom"
                  mode="out-in"
                >
                  <image-getter
                    classes="object-cover  h-full"
                    :url="`/images/product/updated/default.png`"
                    default="/images/product/awaitingimage.webp"
                    :key="group.selected.sku"
                  />
                </transition>
                <div
                  v-if="group.selected.isKitten"
                  class="absolute right-0 top-0 p-3"
                >
                  <div
                    class="rounded bg-pink-400 p-2 py-1 text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Kitten
                  </div>
                </div>
                <!-- <div class="absolute w-full bottom-0   rounded p-3">
                  <div
                    class="flex text-center text-sm divide-x bg-white rounded divide-x"
                  >
                    <div class="flex-1 py-2">60%</div>
                    <div class="flex-1 py-2">££</div>
                    <div class="flex-1 py-2">X</div>
                  </div>
                </div> -->
              </div>
              <div class="py-4 pb-0 text-center text-lg font-semibold">
                {{ group.selected.name }}
              </div>
              <div class="line-clamp-2 px-4 text-center text-sm text-gray-600">
                {{
                  group.selected.foodcomplete
                    ? 'Complete Food'
                    : 'Complementary Food'
                }}
              </div>
            </div>
            <div class="space-y-2 p-4">
              <div class="text-center text-sm">
                Choose from our {{ group.items.length }} flavours
              </div>
              <select
                class="w-full text-sm"
                v-model="group.selected"
                :selected="group.items[0]"
                @change="group.selected.isSelected = true"
              >
                <option
                  v-for="(item, index) in group.items"
                  :key="index"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <!-- create a button that allows you to add in increments-->
            <div class="flex justify-center p-4 pt-0">
              <button
                class="w-full rounded bg-yellow-200 px-4 py-2 font-semibold text-gray-900"
                @click="addFood(group, group.selected)"
              >
                + Add me
              </button>
            </div>
            <div
              v-if="group.items && group.items.length > 0"
              class="flex items-center justify-center space-x-2 pt-2 text-center text-sm"
            >
              <font-awesome-icon
                class="text-xl text-gray-700"
                :icon="['fal', 'box-open']"
              />
              <div>Currently in your box...</div>
            </div>
            <div
              v-if="group.items && group.items.length > 0"
              class="w-full flex-none px-2 py-4 text-sm"
            >
              <div
                class="w-full"
                :key="`added-${index}`"
                v-for="(val, index) in group.items"
              >
                <product-item :product="val" :key="val.sku" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductItem from '@/components/products/ProductItem.vue';
import ImageGetter from '@/components/ImageGetter.vue';
export default {
  components: { ProductItem, ImageGetter },
  name: 'AdjustNew',
  computed: {
    ...mapGetters({
      products: 'getProducts'
    })
  },
  data() {
    return {
      groupedProducts: [],
      mappedTypes: {
        dry: 'Dry',
        wet_can: 'Fillet',
        wet_smooth: 'Smooth',
        wet_tray: 'Pate',
        wet_chunk: 'Chunks',
        wet_tasty: 'Slices',
        wet_pouch: 'Flakes'
      },
      added: []
    };
  },
  methods: {
    addFood(group, selected) {
      if (!group.items) {
        this.$set(group, 'added', []);
      }

      group.items.push({ ...selected, quantity: 1 });
    }
  },
  mounted() {
    //group all products by foodtype, where each group has a label of the foodtype and an array of items that inside it is grouped by the category of the product, each category has a label of the category and an array of products

    //group all products by foodtype, where each group has a label of the foodtype and an array of items ordered alphabetically by name
    let groupedProducts = this.products.reduce((r, a) => {
      r[a.foodtype] = [...(r[a.foodtype] || []), a];
      return r;
    }, {});

    this.groupedProducts = Object.keys(groupedProducts).map((key) => ({
      label: key,
      items: groupedProducts[key].sort((a, b) => a.name.localeCompare(b.name))
    }));

    // for each of the grouped items arrays make the first item have an isSelected property of true and the rest false
    this.groupedProducts.forEach((group) => {
      group.items.forEach((item, index) => {
        if (index === 0) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      this.$set(group, 'selected', group.items[0]);
      this.$set(group, 'isOpen', false);
    });
    const order = [
      'wet_can',
      'wet_pouch',
      'wet_tasty',
      'wet_tray',
      'wet_smooth',
      'dry'
    ];
    // order the grouped products based on the order array
    this.groupedProducts.sort((a, b) => {
      return order.indexOf(a.label) - order.indexOf(b.label);
    });
  }
};
</script>

<style></style>
